.leaflet-marker-icon{
    width: 30px !important;
    height: 30px !important;
    /* margin-left: -15px !important; */
    /* margin-top: 0px !important; */
}

.MuiInputLabel-root{
    color: #366E93 !important;
    font-size: 11pt !important;
    padding-left: 10px !important;
    font-weight: bold !important;
}
.MuiChip-label{
    font-size: 10pt !important;
}

.MuiAutocomplete-inputRoot{
    padding-right: 5px !important;
}

/* .MuiDialogActions-root{
    justify-content: center !important;
}

.cancel_dialog .MuiDialogActions-root {
  justify-content: end !important;
} */

.MuiSlider-markLabel {
  display: none !important;
}
/* .ant-picker-datetime-panel {
  display: inline-flex !important;
} */
@media (max-width: 550px) {
  .ant-picker-datetime-panel {
    display: block !important;
  }
  .ant-picker-dropdown {
    top: 290px !important;
  }
}
/* @media (max-width: 600px) {
    .middle {
      flex-direction: column;
    }
  }
*/
