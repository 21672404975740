.dropdown-check-list {
  display: inline-block;
  position: relative;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  border: 1px solid #ccc;
}

.dropdown-check-list ul {
    min-width: 100%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
  display: flex;
  padding: 5px 10px;
}

.dropdown-check-list ul.items li input{
    margin-top: 4px;
    margin-right: 4px;
}

.dropdown-check-list ul.items li span {
    white-space: nowrap;
    font-size: 15px;
    text-transform: lowercase;
}

.dropdown-check-list.visible .items {
  display: block;
  position: absolute;
  background-color: #FDFDFD;
  top: 38px;
  right: 0;
  z-index: 999999;
  border-radius: 3px;
}

