.animation-container {
  text-align: center;
  margin-top: 20px;
}

.progress-bar {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 50px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 20px auto;
}

.road {
  position: relative;
  height: 100%;
  background-color: rgb(220, 235, 162);
  border-radius: 10px;
  overflow: hidden;
}

.truck {
  position: absolute;
  top: 5px; /* Adjust to align truck vertically */
  width: 40px;
  height: 40px;
  background: url('https://img.icons8.com/ios-filled/50/000000/truck.png') no-repeat center/cover;
  transition: left 0.5s ease-in-out;
}

.percentage {
  position: absolute;
  top: -30px; /* Adjusted to appear above the truck */
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 8px;
  background-color: #17799c;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow for visibility */
}

.success-message {
  margin-top: 10px;
  font-size: 18px;
  color: green;
  font-weight: bold;
}
