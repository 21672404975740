@import-normalize;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiOutlinedInput-input {
  padding: 10px !important;
}
.MuiOutlinedInput-multiline {
  padding: 5px !important;
}
.MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1) !important;
}
.MuiInputLabel-shrink {
  transform: translate(10px, -6px) scale(0.75) !important;
}
/* .css-1kpdewa-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
} */
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0px !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border: 0.5px solid #91b0c4;
}
.MuiInputBase-input {
  height: 1.1876em !important;
}
.MuiOutlinedInput-adornedEnd {
  padding-right: 14px;
  height: 39px;
}
.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.custom_pagination {
  padding: 0px 15px;
}
.custom_pagination .MuiTablePagination-selectLabel {
  margin: 0px;
}
.custom_pagination .MuiTablePagination-displayedRows {
  margin: 0px;
}
.custom_pagination .css-pdct74-MuiTablePagination-selectLabel {
  visibility: hidden;
}
.custom_pagination .css-pdct74-MuiTablePagination-selectLabel::after {
  content: "ITEMS PER PAGE";
  visibility: visible;
  color: #929daf;
  font-size: 13px;
}
.custom_pagination .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  border: 1px solid #707070;
  background: #ffffff;
  border-radius: 2px;
}
.custom_pagination
  .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  padding-right: 36px;
  min-width: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #929daf;
}
.custom_pagination
  .css-194a1fa-MuiSelect-select-MuiInputBase-input.MuiSelect-select {
  min-height: 0;
}
.custom_pagination
  .css-16c50h-MuiInputBase-root-MuiTablePagination-select
  .MuiTablePagination-select {
  padding-left: 2px;
}
.custom_pagination .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-right: 20px;
}
.custom_pagination .css-zylse7-MuiButtonBase-root-MuiIconButton-root,
.custom_pagination .css-zylse7-MuiButtonBase-root-MuiIconButton-root:hover {
  background: #ffffff;
  border-radius: 3px;
  margin-left: 6px;
  font-size: 1.2rem;
}
.custom_pagination .css-levciy-MuiTablePagination-displayedRows {
  font-size: 14px;
  color: #929daf;
}
.custom_table {
  overflow-x: initial;
  overflow: auto;
  margin: 0px 40px 0px 20px;
}
.custom_table .css-39bbo6 {
  border-width: 0px 0px 0px 0px;
}
.custom_table .MuiTableHead-root .MuiTableCell-root {
  padding: 15px 5px;
  color: #fff;
  background-color: #366e93;
  font-size: 12px;
}
.custom_table .css-hbtnrh-MuiTableCell-root {
  border: none;
  color: #8e8e8e;
}
.custom_table .css-6gz8hr-MuiTableCell-root {
  border: none;
  color: #8e8e8e;
}
.custom_table .css-1f7qdgd-MuiTableCell-root {
  border: none;
}
.custom_table .css-1ce3qcx-MuiTableCell-root {
  border: none;
  padding: 20px 0px;
}
.custom_table .css-9mgopn-MuiDivider-root {
  border: none;
}
.custom_table .MuiDivider-root {
  background: none;
}
.custom_table .css-2s90m6-MuiAvatar-root {
  background-color: #366e93;
}
/* .custom_field .MuiAutocomplete-root {
  border: 0.5px solid #91b0c4;
  border-radius: 4px;
} */
.custom_field .MuiFormControl-root {
  border: 0.5px solid #91b0c4;
  border-radius: 4px;
}
.label_field .MuiFormControl-root {
  border: 0.5px solid #91b0c4;
  border-radius: 4px;
}
.custom_field .makeStyles-datePickerField-27 {
  border: 0.5px solid #91b0c4;
  border-radius: 4px;
}
.end_endorment_field .MuiTextField-root,
.start_endorment_field .MuiTextField-root {
  border: 0.5px solid #91b0c4;
  border-radius: 4px;
}
.end_endorment_field .MuiInputBase-input {
  border: none;
}
.start_endorment_field .MuiInputBase-input {
  border-left: 1px solid #91b0c4;
  border-radius: 0px;
}
.phone_field .MuiInputBase-input {
  border: 0;
}
.phone_field .react-tel-input {
  margin-top: 8px;
  border: 0.5px solid #91b0c4;
  border-radius: 4px;
}
.phone_field .react-tel-input .form-control {
  height: 40px;
  width: 100%;
  border-radius: 4px;
}
.ant-upload-span {
  display: flex;
  padding: 10px 0px;
  width: 100%;
}
.ant-upload-list-item-card-actions {
  margin-left: auto;
}
.ant-tooltip-inner {
  display: none;
}
.css-j204z7-MuiFormControlLabel-root {
  border: 1px solid #707070;
  padding-right: 10px;
  border-radius: 3px;
}
.gm-style-iw-a > div {
  z-index: 0;
}
.custom_table .css-1ce3qcx-MuiTableCell-root {
  padding: 20px 10px;
}
.custom_table .css-10dfkli-MuiTableCell-root {
  border-bottom: none !important;
}
.custom_table .css-1ygcj2i-MuiTableCell-root {
  border-bottom: none !important;
}
.custom_card {
  height: 100%;
}
.custom_datagrid {
  padding: 20px 24px;
  width: 100%;
  height: 400;
}
.custom_datagrid .MuiDataGrid-columnHeaders {
  background-color: #366e93;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.custom_datagrid .MuiDataGrid-columnSeparator {
  color: #ffffff;
}
.css-18w7bic .MuiOutlinedInput-root {
  padding: 0px !important;
}
.MuiAutocomplete-endAdornment .css-2iz2x6 .MuiIconButton-root {
  padding: 0px !important;
}
.trip_details_ribbon {
  list-style: none;
  /* display: table; */
  background: #366e93;
  padding: 12px 20px;
  border-radius: 5px;
  color: #ffffff;
  /* position: absolute; */
  /* left: 20px;
  top: 10px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.trip_details_ribbon_1 {
  list-style: none;
  /* display: table; */
  background: #366e93;
  padding: 12px 20px;
  border-radius: 5px;
  color: #ffffff;
  /* position: absolute; */
  /* top: 10px;
  right: 18px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.css-1l6di18 .MuiOutlinedInput-root {
  padding: 0px !important;
}
.css-2iz2x6 {
  position: absolute;
  top: calc(50% - 22px);
}
.ant_custom_multi_select .ant-select-selector {
  padding: 4px !important;
  border: 0.5px solid #91b0c4 !important;
  border-radius: 4px !important;
}
.rc-virtual-list-holder {
  overflow-y: auto !important;
}
.rc-virtual-list-scrollbar {
  display: none;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
  border-radius: 4px !important;
  border: 1px solid #91b0c4 !important;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 40px !important;
}
.custom_select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px !important;
  border-radius: 4px !important;
  border: 1px solid #91b0c4 !important;
}
.custom_select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 40px !important;
}
.custom_select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 40px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px !important;
}
.custom_pagination .ant-pagination {
  text-align: right;
}
.custom_pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 32px !important;
}
.custom_pagination
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 27px !important;
}
.date_picker_field .MuiOutlinedInput-notchedOutline{
  border-width: 0px !important; 
}
.custom_field .MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}
.label_field .MuiOutlinedInput-notchedOutline{
  border-width: 0px !important;
}
.anticon {
  vertical-align: middle !important;
}
@media print {
  html,
  body {
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

.leaflet-div-icon { 
  background: none !important;
  border: none !important;
}