.wrapper_body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
.wrapper {
    width: 430px;
    background: #f0f0f0;
    padding: 30px;
    border-radius: 5px;
}
.wrapper header {
    color: #366E93;
    font-size: 27px;
    font-weight: 600;
    text-align: center;
}
.wrapper form {
    border: 2px dashed #366E93;
    height: 167px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0px;
    cursor: pointer;
}
form :where(p){
    color: #366E93
}
form p {
    font-size: 16px;
    margin-top: 15px;
}
section .upload-row {
    background: #e9f0ff;
    margin-bottom: 10px;
    list-style: none;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.upload-row .row_icon {
    font-size: 30px;
    color: #366E93
}
.progress-area .upload-row .content {
    width: 100%;
    margin-left: 15px;
}
section .details span {
    font-size: 14px;
}
.progress-area .details {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
}
.uploaded-area {
    max-height: 160px;
    overflow-y: auto;
}
.uploaded-area::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: #bbb;
    border-radius: 30px;
}
.uploaded-area::-webkit-scrollbar-thumb {
    background: #366E93;
    border-radius: 30px;
}
.uploaded-area .upload-row .content {
    display: flex;
    align-items: center;
}
.uploaded-area .upload-row .details{
    display: flex;
    margin-left: 15px;
    flex-direction: column;
} 
.uploaded-area .details .size {
    font-size: 11px;
    color: #404040
}
.uploaded-area .upload-row .row_check_icon {
    font-size: 20px;
    color: #366E93;
    vertical-align: middle;
    padding-left: 5px;
}
.uploaded-area .upload-row .row_close_icon {
    font-size: 20px;
    color: #366E93;
    vertical-align: middle;
    padding-left: 5px;
    cursor: pointer;
}
.progress-area .progress-bar { 
    height: 6px;
    width: 100%;
    background: #ffffff;
    margin-bottom: 4px;
    border-radius: 30px;
}
.progress-bar .progress {
    height: 100%;
    width: 0;
    background: #366E93;
    border-radius: inherit;
}