.uploadFile {
  width: 100%;
  border: 1px solid #91b0c4;
  margin-top: 9px;
  color: grey;
  padding: 7.5px 5px;
  border-radius: 4px;
  font-size: 16px;
  overflow: hidden;
  position: relative;
  resize: none;
}
[type="file"] {
  cursor: pointer !important;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 0px;
  z-index: 1;
}
.cam_icon {
  padding: 0px 5px;
  position: absolute;
  right: 0;
  width: 32px !important;
}
.close_icon {
  padding: 0px 5px;
  position: absolute;
  right: 25px;
}
.filename {
  padding: 0px 5px;
}